import axios from "axios";
import { CANDIDATE_FOR_SEND_INVITE } from "./URLS";

export const candidateDetailsForInvites = async (filterData,number) => {
  var URL = CANDIDATE_FOR_SEND_INVITE;
  URL=URL+`?pageNumber=${number}`
  let Config = {};
  const data = { ...filterData };
  return await axios.post(URL, data, Config).then((res) => res.data);
};
