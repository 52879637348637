import { useEffect, useState } from "react";
import "./SendInvites.scss";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
import { CandidateProfile } from "../../Components/Common/CandidateProfile/CandidateProfile";
import Popup from "../../Components/Common/popUp/popUp";
import { candidateDetailsForInvites } from "../../APIS/candidateForSendingInvites";
import { confirmAllInviteSend } from "../../APIS/confirmInviteSend";
import { getAllCountries } from "../../APIS/getCountries";
import Dropdown from "react-dropdown";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { refreshCandidatOfac } from "../../APIS/refreshOfacStatus";
import { Header } from "../../Components/Header/Header";
import Pagination from "@mui/material/Pagination";

const Headers = [
  "Candidate Name",
  "Email ID",
  "Availability Status",
  "Location",
  "Experience",
  "Rating",
  "Skills",
  "OFAC",
  "Nationality",
  "Actions",
];

const todaysDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const initialFilters = [
  {
    mandatorySkill: null,
    minYearsOfExperience: null,
    location: null,
    country: null,
    availability: "",
    rating: 0,
    jobRole: null,
    ofacStatus: null,
  },
];

export const SendJobInvites = () => {
  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([...Headers]);
  const [selectedList, setSelectedList] = useState([]);
  const [viewSelectedProfiles, setViewSelectedProfiles] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [filterOptions,setFilteroptions]=useState([])
  const [pageNumber,setPageNumber]=useState(1)
  useEffect(()=>{
    handleGetCandidateForInvites();
  },[pageNumber])
  const [filters, setFilters] = useState(() => {
    const dataFromSession = JSON.parse(sessionStorage.getItem("prefilledData"));

    let ratings = String(dataFromSession?.minimumCandidateRatings)?.split(".");

    let temp = {
      ...initialFilters,
      rating: Number(ratings[0]),
    };
    return { ...temp };
  });
  const [reset, setReset] = useState(false);
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/dashboard");
  };

  const handleSelect = (emailIdToCheck) => {
    // const emailIdToCheck = tableData[index]["Email ID"];
    const foundIndex = selectedList.findIndex(
      (item) => item["email"] === emailIdToCheck
    );

    console.log("foundIndex=>", foundIndex);

    if (foundIndex !== -1) {
      // If found, remove the item from selectedList
      const updatedSelectedList = [
        ...selectedList.slice(0, foundIndex),
        ...selectedList.slice(foundIndex + 1),
      ];
      setSelectedList(updatedSelectedList);
    } else {
      // If not found, add the item to selectedList
      const dataIndex = tableData.findIndex(
        (item) => item["email"] === emailIdToCheck
      );
      setSelectedList((prev) => prev.concat(tableData[dataIndex]));
    }
  };

  const handleViewSelectedProfiles = () => {
    if (selectedList.length > 0) setViewSelectedProfiles(true);
  };

  const getAvailability = () => {
    const data = filters?.availability;
    const newData = data.split("-").reverse().join("-");
    return newData;
  };

  const handleGetCandidateForInvites = async () => {
    try {
      // const csmMailID = sessionStorage.getItem('mail')
      const jobId = sessionStorage.getItem("jobId");
      // const jobId = "100";
      const availabilityData = filters?.availability ? getAvailability() : null;
      const data = {
        csmMailID: sessionStorage.getItem("email"),
        uniqueJobID: jobId,
        jobRole: filters?.jobRole ? filters?.jobRole : null,
        mandatorySkill:
          filters?.mandatorySkill?.length > 0 ? filters?.mandatorySkill : null,
        minYearsOfExperience: filters?.minYearsOfExperience
          ? filters?.minYearsOfExperience
          : null,
        location: filters?.location ? filters?.location : null,
        country: filters?.country ? filters?.country : null,
        availability: availabilityData,
        rating: filters?.rating ? Number(filters?.rating) : 3,
        ofacStatus:
          filters?.ofacStatus === "True"
            ? true
            : filters?.ofacStatus === "False"
            ? false
            : null,
      };
      console.log("submitting new data==> ", data);
      const res = await candidateDetailsForInvites(data,pageNumber-1);
      if (res) {
        // setSelectedList([]);
        console.log("handleGetCandidateForInvites==>", res);
        setTableData([...res?.filteredResponse || []]);
        setFilteroptions(res?.locationDropDown || {})
      }
    } catch (err) {
      console.log("handleGetCandidateForInvites error===>", err);
    }
  };

  const handleGetCountries = async () => {
    const res = await getAllCountries();
    setAllCountries([...res]);
  };

  const handleConfirmInvites = async () => {
    try {
      let temp = [];
      selectedList.forEach((item) => {
        temp.push(item?.email);
      });
      const data = {
        csmMailID: sessionStorage.getItem("email"),
        name: [...temp],
        uniqueJobID: Number(sessionStorage.getItem("jobId")),
      };
      const res = await confirmAllInviteSend(data);
      console.log("handleConfirmInvites==>", res);
      if (res) {
        setSelectedList([]);
        setViewSelectedProfiles(false);
        handleGetCandidateForInvites();
      }
    } catch (err) {
      console.log("handleConfirmInvites error==>", handleConfirmInvites);
    }
  };

  const handleGo = () => {
    // console.log(filters);
    setSelectedList([]);
    handleGetCandidateForInvites();
  };

  const handleReset = () => {
    setFilters({ ...initialFilters });
    setReset(true);
  };

  const handleRefreshOfac = async () => {
    if (selectedList?.length === 0) {
      return;
    }
    try {
      let temp = [];
      selectedList.forEach((item) => {
        temp.push(item?.email);
      });
      const data = {
        candidateMails: [...temp],
      };
      const res = await refreshCandidatOfac(data);
      console.log("handleConfirmInvites==>", res);
      if (res) {
        // setSelectedList([]);
        // setViewSelectedProfiles(false);
        handleGetCandidateForInvites();
      }
    } catch (err) {
      console.log("handleRefreshOfac error==>", err);
    }
  };

  useEffect(() => {
    handleGetCountries();
    handleGetCandidateForInvites();
  }, []);

  useEffect(() => {
    if (reset) {
      handleGetCandidateForInvites();
      setReset(false);
    }
  }, [filters]);

  return (
    <>
      <Header showDropdowns={true} />
      <div className="InvitesWrapper">
        <div className="homeButton">
          <button onClick={() => goToHome()}>
            <ChevronLeftIcon /> <span> Home </span>
          </button>
        </div>
        <div className="selectTitle">
          <div>Select Candidates for Invite</div>
          <div>
            <button onClick={() => handleRefreshOfac()}>Refresh ofac</button>
            <button onClick={() => handleViewSelectedProfiles()}>
              Confirm & Send invite ({selectedList?.length})
            </button>
          </div>
        </div>
        {allCountries.length > 0 && (
          <AllFilters
            filters={filters}
            setFilters={setFilters}
            countryList={allCountries}
            handleGo={handleGo}
            handleReset={handleReset}
            filterOptions={filterOptions}
          />
        )}
        <CandidateList
          tableData={tableData}
          tableHeaders={tableHeaders}
          handleSelect={handleSelect}
          selectedList={selectedList}
        />
      </div>
      <PagesAndCount
          currentPage={pageNumber}
          setCurrentPage={setPageNumber}
        />
      {viewSelectedProfiles && (
        <ViewSelectedCandidateProfiles
          setViewSelectedProfiles={setViewSelectedProfiles}
          selectedList={selectedList}
          tableHeaders={tableHeaders}
          handleSelect={handleSelect}
          handleConfirmInvites={handleConfirmInvites}
        />
      )}
    </>
  );
};

const PagesAndCount = ({ currentPage, setCurrentPage }) => {
  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage, setItemsPerPage] = useState(10);

  // const handleChange = (event) => {
  //   setItemsPerPage(event.target.value);
  // };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <div className="pagesAndCount">
      {/* <div className="itemsPerPage">
        <span>Items per page</span>
        <Select
          value={itemsPerPage}
          onChange={handleChange}
          className="selectClass"
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
      </div> */}
      <div className="pageNo">
        <Pagination
          page={currentPage}
          onChange={handlePageChange}
          count={100}
          className="pages"
        />
      </div>
      {/* <div className="itemCount">
        Showing <span>1-10 of 200</span>
      </div> */}
    </div>
  );
};

const AllFilters = ({
  filters,
  setFilters,
  countryList,
  handleGo,
  handleReset,filterOptions
}) => {
  console.log(filterOptions?.city,"check1")
  const [allOptions, setAllOptions] = useState([
    {
      label: "Experience",
      placeholder: "Select",
      options: [
        {
          value: "1",
          label: "1 year",
        },
        {
          value: "2",
          label: "2 year",
        },
        {
          value: "3",
          label: "3 year",
        },
        {
          value: "4",
          label: "4 year",
        },
        {
          value: "5",
          label: "5 year",
        },
        {
          value: "6",
          label: "6 year",
        },
        {
          value: "7",
          label: "7 year",
        },
      ],
      disabled: false,
      name: "minYearsOfExperience",
      value: filters?.minYearsOfExperience,
    },
    {
      label: "Location",
      placeholder: "Select",
      options: [...filterOptions?.city || []],
      disabled: false,
      name: "location",
      value: filters?.location,
    },
    {
      label: "Country",
      placeholder: "All",
      options: [...countryList],
      disabled: false,
      name: "country",
      value: filters?.country,
    },
    {
      label: "Availability",
      type: "date",
      disabled: false,
      name: "availability",
      value: filters?.availability,
    },
    {
      label: "Rating",
      placeholder: "All",
      options: ["1", "2", "3", "4", "5"],
      disabled: false,
      name: "rating",
      value: filters?.rating,
    },
    {
      label: "Role",
      placeholder: "Select",
      options: ["Full Stack", "Backend", "Frontend", "Data Science"],
      disabled: false,
      name: "jobRole",
      value: filters?.jobRole,
    },
    {
      label: "OFAC",
      placeholder: "Select",
      options: ["True", "False"],
      disabled: false,
      name: "ofacStatus",
      value: filters?.ofacStatus,
    },
  ]);
  useEffect(()=>{
    if(filterOptions){
        setAllOptions([
          {
            label: "Experience",
            placeholder: "Select",
            options: [
              {
                value: "1",
                label: "1 year",
              },
              {
                value: "2",
                label: "2 year",
              },
              {
                value: "3",
                label: "3 year",
              },
              {
                value: "4",
                label: "4 year",
              },
              {
                value: "5",
                label: "5 year",
              },
              {
                value: "6",
                label: "6 year",
              },
              {
                value: "7",
                label: "7 year",
              },
            ],
            disabled: false,
            name: "minYearsOfExperience",
            value: filters?.minYearsOfExperience,
          },
          {
            label: "Location",
            placeholder: "Select",
            options: [...filterOptions?.city || []],
            disabled: false,
            name: "location",
            value: filters?.location,
          },
          {
            label: "Country",
            placeholder: "All",
            options: [...countryList],
            disabled: false,
            name: "country",
            value: filters?.country,
          },
          {
            label: "Availability",
            type: "date",
            disabled: false,
            name: "availability",
            value: filters?.availability,
          },
          {
            label: "Rating",
            placeholder: "All",
            options: ["1", "2", "3", "4", "5"],
            disabled: false,
            name: "rating",
            value: filters?.rating,
          },
          {
            label: "Role",
            placeholder: "Select",
            options: ["Full Stack", "Backend", "Frontend", "Data Science"],
            disabled: false,
            name: "jobRole",
            value: filters?.jobRole,
          },
          {
            label: "OFAC",
            placeholder: "Select",
            options: ["True", "False"],
            disabled: false,
            name: "ofacStatus",
            value: filters?.ofacStatus,
          },
        ])
    }
  },[filterOptions])

  const handleSkills = (e) => {
    let temp = filters?.mandatorySkill ? [...filters?.mandatorySkill] : [];

    const optionIndex = temp.findIndex((o) => o === e.value);

    if (optionIndex === -1) {
      temp.push(e.value);
    } else {
      // If the value exists in the array, remove it
      temp = temp.filter((value) => value !== e.value);
    }

    setFilters((prev) => {
      return { ...prev, ["mandatorySkill"]: temp };
    });
  };

  const handleChange = (e, name) => {
    console.log(e, name);
    setFilters((prev) => {
      return { ...prev, [name]: e.value };
    });
  };

  return (
    <div className="allfiltersInvite">
      <div>
        <label>Skills</label>

        <Dropdown
          options={[
            "React JS",
            "Angular JS",
            "Java",
            "JavaScript",
            "Spring",
            "Hibernate",
            "Cassandra",
            "MySQL",
            "Python",
            "Django",
          ]}
          onChange={handleSkills}
          value={
            filters?.mandatorySkill ? filters?.mandatorySkill.join(",") : ""
          }
          placeholder={"Select"}
          className="dropdown"
          controlClassName="myControlClassName"
          menuClassName="myMenuClassName"
        />
      </div>

      {allOptions.map((ele, index) => (
        <div key={index}>
          <label>{ele?.label}</label>
          {ele?.type === "date" ? (
            <input
              type="date"
              key={index}
              onChange={(e) =>
                handleChange({ value: e.target.value }, "availability")
              }
              value={filters?.[ele.name] ? filters?.[ele.name] : ""}
              // value=""
              name={ele?.name}
            />
          ) : (
            <Dropdown
              key={index}
              options={ele.options}
              onChange={(e) => handleChange(e, ele.name)}
              placeholder={ele?.placeholder}
              value={filters?.[ele.name]}
              className="dropdown"
              controlClassName="myControlClassName"
              menuClassName="myMenuClassName"
            />
          )}
        </div>
      ))}

      <div>
        <button onClick={() => handleGo()}>Go</button>
      </div>
      <div>
        <button title="reset" onClick={() => handleReset()}>
          {" "}
          <span>
            <RestartAltIcon />
          </span>{" "}
        </button>
      </div>
    </div>
  );
};

const CandidateList = ({
  tableHeaders,
  tableData,
  handleSelect,
  selectedList,
}) => {
  const [viewProfile, setViewProfile] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState("");
  const navigate=useNavigate();
  const handleViewProfile = (candidateID) => {
    //setViewProfile(true);
    setSelectedProfile(candidateID);
   
    const data={email:candidateID}
        navigate("/candidate-info", { state: data });
  };

  const handleCheckSelect = (emailIdToCheck) => {
    // console.log("called handleCheckSelect");
    // const emailIdToCheck = tableData[index]["Email ID"];
    const foundIndex = selectedList.findIndex(
      (item) => item["email"] === emailIdToCheck
    );

    return foundIndex !== -1 ? "Selected" : "Select";
  };

  return (
    <>
      <div className="sendInvitesWrapper">
        <table className="applicationTableContainer">
          <thead className="tableHead">
            <tr className="tableRow">
              {tableHeaders?.map((header, index) => (
                <th key={index} className="headerContent">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="tableBody">
            {tableData?.map((data, index) => (
              <tr key={index}>
                <td>{data?.candidateName}</td>
                <td>{data?.email}</td>
                <td>{data?.availabilityStatus}</td>
                <td>{data?.location}</td>
                <td>{data?.experience}</td>
                <td>{data?.rating}</td>
                <td className="skills">{data?.skills.join(", ")}</td>
                <td>
                  {`${data?.ofacStatus}`.charAt(0).toUpperCase() +
                    `${data?.ofacStatus}`.slice(1)}
                </td>
                <td>{data?.citizenship}</td>
                <td>
                  <button
                    className="viewProfile"
                    onClick={() => handleViewProfile(data?.email)}
                  >
                    View Profile
                  </button>
                  <button
                    // className="selectClass"
                    // className="selectedClass"
                    className={
                      handleCheckSelect(data?.email) === "Selected"
                        ? "selectedClass"
                        : "selectClass"
                    }
                    onClick={() => handleSelect(data?.email)}
                  >
                    {handleCheckSelect(data?.email)}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {viewProfile && (
        <CandidateProfile
          candidateId={selectedProfile}
          setViewProfile={setViewProfile}
        />
      )}
    </>
  );
};

const ViewSelectedCandidateProfiles = ({
  setViewSelectedProfiles,
  selectedList,
  tableHeaders,
  handleSelect,
  handleConfirmInvites,
}) => {
  return (
    <Popup>
      <div className="selectedProfiles">
        <div
          className="closeProfiles"
          onClick={() => setViewSelectedProfiles(false)}
        >
          <img src={"./Images/icons/Close.svg"} alt="cut" />
        </div>
        <div className="title">Selected profiles</div>
        <div className="sendInvitesWrapper">
          <table className="applicationTableContainer">
            <thead className="tableHead">
              <tr className="tableRow">
                {tableHeaders?.map((header, index) => (
                  <th key={index} className="headerContent">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="tableBody">
              {selectedList?.map((data, index) => (
                <tr key={index}>
                  <td>{data?.candidateName}</td>
                  <td>{data?.email || ""}</td>
                  <td>{data?.availabilityStatus|| ""}</td>
                  <td>{data?.location}</td>
                  <td>{data?.experience || "p"}</td>
                  <td>{data?.rating || ""}</td>
                  <td className="skills">{data?.skills.join(", ")}</td>
                  <td>{data?.ofacStatus ? "True" : "False"}</td>
                  <td>{data?.citizenship}</td>
                  <td>
                    <button
                      className="removeButton"
                      onClick={() => handleSelect(data?.email)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <button
            disabled={selectedList.length > 0 ? false : true}
            style={{ opacity: selectedList.length > 0 ? "1" : ".7" }}
            className="confirmButton"
            onClick={() => handleConfirmInvites()}
          >
            Confirm
          </button>
        </div>
      </div>
    </Popup>
  );
};
