import { useNavigate } from "react-router-dom";
import "./Invites.scss";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useEffect, useState } from "react";
import { inviteDataOfJob } from "../../APIS/inviteDataForJob";
import { CandidateProfile } from "../../Components/Common/CandidateProfile/CandidateProfile";
import FilterListIcon from "@mui/icons-material/FilterList";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { changeCandidateStatus } from "../../APIS/changeCandidateStatus";
import { Header } from "../../Components/Header/Header";
const Headers = [
  "",
  "Candidate Name",
  "Mobile",
  "Availability Status",
  "Experience",
  "Rating",
  "Skills",
  "Status",
  "Actions",
];

export const InvitesPage = () => {
  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([...Headers]);
  const [viewProfile, setViewProfile] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState("");
  const [selectOptions, setSelectOptions] = useState([
    {
      label: "Change Status",
      value: "Change Status",
    },
    {
      label: "In-progress",
      value: "In-progress",
    },
    {
      label: "Onboarded",
      value: "Onboarded",
    },
    {
      label: "Shortlisted",
      value: "Shortlisted",
    },
    {
      label: "Not Selected",
      value: "Not Selected",
    },
  ]);
  const [selectedStatus, setSelectedStatus] = useState("Change Status");
  const [viewStatusFilter, setViewStatusFilter] = useState(false);
  const [selectedValueStatus, setSelectedValueStatus] = useState("");

  const [selectedCandidates, setSelectedCandidates] = useState([]);

  const navigate = useNavigate();

  function handleChangeStatus(email) {
    if (selectedCandidates.includes(email)) {
      // Candidate is already selected, so remove them
      setSelectedCandidates(
        selectedCandidates.filter((candidateEmail) => candidateEmail !== email)
      );
    } else {
      // Candidate is not selected, so add them
      setSelectedCandidates([...selectedCandidates, email]);
    }
  }

  const goToHome = () => {
    navigate("/dashboard");
  };

  const handleViewProfile = (email) => {
    // console.log(email);
    setSelectedProfile(email);
    //setViewProfile(true);
    //setSelectedProfile(candidateID);
    const data={email:email}
        navigate("/candidate-info", { state: data });
  };

  const getAllInviteData = async () => {
    try {
      const jobId = sessionStorage.getItem("jobId");
      const data = {
        uniqueJobID: jobId,
        status: selectedValueStatus ? selectedValueStatus : null,
      };
      const res = await inviteDataOfJob(data);
      console.log("getAllInviteData==>", res);
      setTableData([...res]);
    } catch (err) {
      console.log("getAllInviteData error ==>", err);
    }
  };

  const getAllInviteDataForReset = async () => {
    try {
      const jobId = sessionStorage.getItem("jobId");
      const data = {
        uniqueJobID: jobId,
        status: null,
      };
      const res = await inviteDataOfJob(data);
      // console.log("getAllInviteData==>", res);
      setTableData([...res]);
    } catch (err) {
      console.log("getAllInviteDataForReset error ==>", err);
    }
  };

  const saveCandidateNewStatus = async () => {
    try {
      const jobId = sessionStorage.getItem("jobId");
      const data = {
        uniqueJobID: jobId,
        candidates: [...selectedCandidates],
        status: selectedStatus,
      };
      const res = await changeCandidateStatus(data);
      if (res) {
        setSelectedCandidates([]);
        setSelectedValueStatus("");
        getAllInviteData();
      }
      // console.log("saveCandidateNewStatus ==>", res);
    } catch (err) {
      console.log("handleStatusChange error==>", err);
    }
  };

  const handleStatusChange = async (val) => {
    // console.log("selected===>", selectedCandidates, val);
    setSelectedStatus(val);
  };

  useEffect(() => {
    getAllInviteData();
  }, []);

  useEffect(() => {
    if (selectedStatus !== "Change Status") {
      saveCandidateNewStatus();
    }
  }, [selectedStatus]);

  return (
    <>
      <Header showDropdowns={true} />
      <div className="sentInvitePage">
        <div className="homeButton">
          <button onClick={() => goToHome()}>
            <ChevronLeftIcon /> <span> Home </span>
          </button>
        </div>
        <div className="inviteTitle">Sent Invites</div>

        <FiltersSection
          selectOptions={selectOptions}
          handleStatusChange={handleStatusChange}
          selectedStatus={selectedStatus}
          setViewStatusFilter={setViewStatusFilter}
          selectedCandidates={selectedCandidates}
        />

        <SentInvites
          tableHeaders={tableHeaders}
          tableData={tableData}
          handleViewProfile={handleViewProfile}
          selectedCandidates={selectedCandidates}
          handleChangeStatus={handleChangeStatus}
        />
      </div>
      {viewProfile && (
        <CandidateProfile
          candidateId={selectedProfile}
          setViewProfile={setViewProfile}
        />
      )}
      {viewStatusFilter && (
        <StatusFilters
          setViewStatusFilter={setViewStatusFilter}
          selectedValueStatus={selectedValueStatus}
          setSelectedValueStatus={setSelectedValueStatus}
          getAllInviteData={getAllInviteData}
          getAllInviteDataForReset={getAllInviteDataForReset}
        />
      )}
    </>
  );
};

const SentInvites = ({
  tableHeaders,
  tableData,
  handleViewProfile,
  selectedCandidates,
  handleChangeStatus,
}) => {
  return (
    <div className="sent-invites">
      <table className="applicationTableContainer">
        <thead className="tableHead">
          <tr className="tableRow">
            {tableHeaders?.map((header, index) => (
              <th key={index} className="headerContent">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="tableBody">
          {tableData?.map((data, index) => (
            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedCandidates.includes(data?.candidateId)}
                  onChange={() => handleChangeStatus(data?.candidateId)}
                />
              </td>
              <td>{data?.candidateName}</td>
              <td>{data?.mobile}</td>
              <td>{data?.availability}</td>
              <td>{data?.experience}</td>
              <td>{data?.rating}</td>
              <td className="skills">{data?.skills.join(", ")}</td>
              <td>
                {data?.inviteStatus === "Active"
                  ? "Pending"
                  : data?.inviteStatus}
              </td>
              <td>
                <button
                  className="sendButton"
                  onClick={() => handleViewProfile(data?.candidateId)}
                >
                  View Profile
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const FiltersSection = ({
  selectOptions,
  handleStatusChange,
  selectedStatus,
  setViewStatusFilter,
  selectedCandidates,
}) => {
  const handleChange = (e) => {
    if (e.target.value === selectOptions?.[0]?.value) return;
    handleStatusChange(e.target.value);
  };
  return (
    <div className="filteraSection">
      {selectedCandidates.length > 0 && (
        <div>
          <Select
            value={selectedStatus}
            onChange={handleChange}
            className="selectClass"
          >
            {selectOptions?.map(
              (item, index) =>
                item?.value &&
                (index === 0 ? (
                  <MenuItem
                    value={item?.value}
                    key={index}
                    style={{ fontStyle: "italic", opacity: ".7" }}
                  >
                    {item?.label}
                  </MenuItem>
                ) : (
                  <MenuItem value={item?.value} key={index}>
                    {item?.label}
                  </MenuItem>
                ))
            )}
          </Select>
        </div>
      )}
      <div className="filersButton" onClick={() => setViewStatusFilter(true)}>
        <button>Filters</button>
        <FilterListIcon />
      </div>
    </div>
  );
};

const StatusFilters = ({
  setViewStatusFilter,
  selectedValueStatus,
  setSelectedValueStatus,
  getAllInviteData,
  getAllInviteDataForReset,
}) => {
  function handleChange(event) {
    setSelectedValueStatus(event.target.value);
  }

  function handleBoxClick(e) {
    if (e.target.id === "mainBox") {
      setViewStatusFilter(false);
    }
  }

  return (
    <div
      className="radioFilters"
      id="mainBox"
      onClick={(e) => handleBoxClick(e)}
    >
      <div className="popupMainWrapper">
        <div className="filters">Filters</div>
        <div className="status">Status</div>
        <div className="popupMain">
          <RadioGroup value={selectedValueStatus} onChange={handleChange}>
            <FormControlLabel
              value="Accepted"
              control={<Radio />}
              label="Accepted"
            />
            <FormControlLabel
              value="Rejected"
              control={<Radio />}
              label="Rejected"
            />
            <FormControlLabel
              value="Active"
              control={<Radio />}
              label="Pending"
            />
            <FormControlLabel
              value="Onboarded"
              control={<Radio />}
              label="Onboarded"
            />
            <FormControlLabel
              value="Shortlisted"
              control={<Radio />}
              label="Shortlisted"
            />
          </RadioGroup>
        </div>
        <div className="buttons">
          <button
            className="reset"
            onClick={() => {
              setSelectedValueStatus("");
              getAllInviteDataForReset();
              setViewStatusFilter(false);
            }}
          >
            Reset
          </button>
          <button
            className="save"
            onClick={() => {
              getAllInviteData();
              setViewStatusFilter(false);
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
