import  React,{useState,useEffect,useRef} from 'react';
import { Dialog,DialogTitle,TextField,Typography,IconButton,Stack, Button } from '@mui/material';
import { formatDateWithYearAndMonth, calculateTotalExperience } from "../../../../utils/dateUtils";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CircleIcon from "@mui/icons-material/Circle";
import circleWithGreenDot from "../../../../assets/svgs/circle-with-green-dot.svg";
import UpdateButton from '../UpdateButton';


import CloseIcon from '@mui/icons-material/Close';



const ReccomandationAdd = ( {open,onClose,handleSumbit}) => {
    const [work,setWork]=useState({})
    const [openSkill,setOpenSkill]=useState(false);
   const index=0
   const [errors,setErrors]=useState({})

   const validateFields = () => {
    let fieldErrors = {};
   
      fieldErrors={
        recommenderName: !work.recommenderName,
        recommendationNote: !work.recommendationNote,
        recommenderRole: !work.recommenderRole,
       
      };
    
    setErrors(fieldErrors);
    return !Object.values([fieldErrors]).some((error) => Object.values(error).includes(true));
  };
    const onFieldChange = (index, name, value) => {
        setWork((prevWork) => ({
          ...prevWork,
          [name]: value,
        }));
      };
    
  
    // Convert initial highlights array to a string with bullets
  
  
    // Function to handle changes in the text area
    
  
    // Function to save highlights as an array of strings
    
    // Function to handle start date change and format it to "MM/YYYY"
    
  
    // Function to handle end date change and format it to "MM/YYYY"
    
    // Function to remove a skill from the tech stack
   
  
    // Scroll to the bottom when content changes
   
   
      const handleClickUpdate=()=>{
        if(validateFields()){
        handleSumbit(work);
        }
      }

     

      const textFieldRef = useRef(null);

  // Scroll to the bottom when content changes
  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.scrollTop = textFieldRef.current.scrollHeight;
    }
  }, []);
      
      
      
  
    return (
        
       
        
        <Dialog
        open={open}
        maxWidth="xl"
        fullWidth
        sx={{ padding: '20px' }}
        PaperProps={{
          sx: {
            width: '800px', // Set a custom width
            margin: 'auto', // Center the dialog
          },
        }}
      >
        {/* Title and Add More Button */}
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 0,
          }}
        >
          <span>Add Recommendation</span>
          <IconButton
            onClick={() => {
              setWork({});
              onClose();
            }}
            sx={{
              color: '#062B6E',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      
        <div
          className="work-experience-item"
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            padding: '20px',
            boxSizing: 'border-box',
          }}
        >
          {/* Role Field */}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              placeholder="Role"
              error={!!errors.recommenderRole}
              value={work.recommenderRole || ''}
              onChange={(e) => onFieldChange(index, 'recommenderRole', e.target.value)}
              variant="outlined"
              size="small"
              sx={{
                width: '300px',
                input: {
                  color: '#212121',
                  fontFamily: 'Poppins',
                  fontSize: '18px',
                  fontWeight: 600,
                  lineHeight: 'normal',
                  letterSpacing: '0.2px',
                },
              }}
            />
          </div>
      
          {/* Name Field */}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
             error={!!errors.recommenderName}
              placeholder="Name"
              value={work.recommenderName || ''}
              onChange={(e) => onFieldChange(index, 'recommenderName', e.target.value)}
              variant="outlined"
              size="small"
              sx={{
                width: '300px',
                input: {
                  color: '#4f4f4f',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: 'normal',
                  letterSpacing: '0.2px',
                },
              }}
            />
          </div>
      
          {/* Note Field */}
          <TextField
           error={!!errors.recommendationNote}
            placeholder="Note"
            value={work.recommendationNote || ''}
            onChange={(e) => onFieldChange(index, 'recommendationNote', e.target.value)}
            multiline
            rows={4}
            variant="outlined"
            size="small"
            fullWidth
            sx={{
              marginTop: '8px',
              input: {
                color: '#4F4F4F',
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '23px',
                letterSpacing: '0.2px',
              },
            }}
          />
      
          <UpdateButton handleClickUpdateButton={handleClickUpdate} />
        </div>
      </Dialog>
      
      
      
      
        
        
    );
  };

export default ReccomandationAdd;